
import store from '@/store/index.js';
import VueRouter from "vue-router";
import routes from "./botroutes";


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach(function(to, _, next) {
    
    store.dispatch("tryLogin");

    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        console.log('isAuthenticated');
        console.log(to.meta.requiresAuth);
        console.log(store.getters.isAuthenticated);
        //next();
        //next('/login');
    } else if (!to.meta.requiresAuth && store.getters.isAuthenticated) {
        next('/dashboard');
    } else {
        next();
    }
});

export default router;
