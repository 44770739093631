export default {
  email(state) {
    return state.email;
  },
  gravatar_url(state) {
    return state.gravatar_url;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  }
};