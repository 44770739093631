let timer;

export default {
  async login(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'login'
    });
  },
  async signup(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'signup'
    });
  },
  async auth(context, payload) {
    const mode = payload.mode;



    //let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCP6jBINNH0Dwac79WvOSMhyAESYtBL1is';

    //if (mode === 'signup') {
    //  url =
    //    'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCP6jBINNH0Dwac79WvOSMhyAESYtBL1is';
    //}

    /*
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
        returnSecureToken: true
      })
    });
    */
    //const responseData = await response.json();

    /*
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }*/

    //const expiresIn = +responseData.expiresIn * 1000;
    // const expiresIn = 5000;
    //const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem('email', payload.email);
    localStorage.setItem('token', payload.token);
    localStorage.setItem('gravatar_url', payload.gravatar_url);
    //localStorage.setItem('tokenExpiration', expirationDate);

    /*
    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);
*/


    context.commit('setUser', {
      token: payload.token,
      email: payload.email,
      gravatar_url: payload.gravatar_url
    });



  },
  tryLogin(context) {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const gravatar_url = localStorage.getItem('gravatar_url');
    //const tokenExpiration = localStorage.getItem('tokenExpiration');

    /*
    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      return;
    }
*/
    //console.log('tryLogin');
    /*
    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);
    */

    if (token && email) {
      context.commit('setUser', {
        token: token,
        email: email,
        gravatar_url: gravatar_url
      });
    }
  },
  logout(context) {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('gravatar_url');

    
    //localStorage.removeItem('tokenExpiration');

    //clearTimeout(timer);

    context.commit('setUser', {
      token: null,
      email: null,
      gravatar_url: null
    });
  },
  autoLogout(context) {
    //context.dispatch('logout');
    //context.commit('setAutoLogout');
  }
};
