import DashboardLayout from "@/layout/botdashboard/DashboardLayout.vue";
import AuthLayout from "@/layout/authdashboard/DashboardLayout.vue";

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
//const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Market = () => import(/* webpackChunkName: "common" */ "@/pages/Market.vue");
const Bots = () => import(/* webpackChunkName: "common" */ "@/pages/Bots.vue");
const BotEdit = () => import(/* webpackChunkName: "common" */ "@/pages/BotEdit.vue");
const BotEditBuyLow = () => import(/* webpackChunkName: "common" */ "@/pages/BotEditBuyLow.vue");
const BotEditGrid = () => import(/* webpackChunkName: "common" */ "@/pages/BotEditGrid.vue");

const LoginForm = () => import(/* webpackChunkName: "common" */ "@/pages/LoginForm.vue");
const RegisterForm = () => import(/* webpackChunkName: "common" */ "@/pages/RegisterForm.vue");

const routes = [
  {
    path: "/",
    component: AuthLayout,
    redirect: "/login",
    children: [
        {
          path: "login",
          name: "login",
          component: LoginForm
        },
        {
            path: "register",
            name: "register",
            component: RegisterForm
        }
    ]
  },
  {
    path: "/",
    component: DashboardLayout,
    //redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        meta: { requiresAuth: true },
        component: Dashboard
      },
      {
        path: "profile",
        name: "profile",
        meta: { requiresAuth: true },
        component: Profile
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },/*
      {
        path: "maps",
        name: "maps",
        component: Maps
      },*/
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      },
      {
        path: "market",
        name: "market",
        meta: { requiresAuth: true },
        component: Market
      },
      {
        path: "bots",
        name: "bots",
        meta: { requiresAuth: true },
        component: Bots
      },
      {
        path: "bots/edit/:botId/",
        name: "bots-edit",
        meta: { requiresAuth: true },
        component: BotEdit,
        props: true
      },
      {
        path: "bots/edit/",
        name: "bots-add",
        meta: { requiresAuth: true },
        component: BotEdit,
        props: true
      },
      {
        path: "bots/edit_buy_low/:botId/",
        name: "bots-edit-buy-low",
        meta: { requiresAuth: true },
        component: BotEditBuyLow,
        props: true
      },
      {
        path: "bots/edit_buy_low/",
        name: "bots-add-buy-low",
        meta: { requiresAuth: true },
        component: BotEditBuyLow,
        props: true
      },
      {
        path: "bots/edit_grid/:botId/",
        name: "bots-edit-grid",
        meta: { requiresAuth: true },
        component: BotEditGrid,
        props: true
      },
      {
        path: "bots/edit_grid/",
        name: "bots-add-grid",
        meta: { requiresAuth: true },
        component: BotEditGrid,
        props: true
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
