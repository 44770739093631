<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="https://cryptolumi.com">
            CryptoLumi
          </a>
        </li>
        <!--
        <li class="nav-item">
          <a class="nav-link" href="https://blog.cryptolumi.com">
            Blog
          </a>
        </li>
        
        <li class="nav-item">
          <a class="nav-link" href="https://cryptolumi.com/license">
            Licenses
          </a>
        </li>
        -->
      </ul>
      <div class="copyright">
        © {{year}} made with <i class="tim-icons icon-heart-2"></i> by
        <a href="https://www.swit.hr" target="_blank" rel="noopener">SWIT</a>&nbsp; for profitable Trading.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
